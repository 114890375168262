//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-956:_8916,_8896,_88,_8,_6596,_1376,_148,_9688;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-956')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-956', "_8916,_8896,_88,_8,_6596,_1376,_148,_9688");
        }
      }catch (ex) {
        console.error(ex);
      }