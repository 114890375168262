//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-956:_6892,_804,_2408,_8472,_840,_332,_1128,_1064;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-956')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-956', "_6892,_804,_2408,_8472,_840,_332,_1128,_1064");
        }
      }catch (ex) {
        console.error(ex);
      }